<template>
    <button class="app-icon-button" variant="none" @click="handleClick">
        <b-icon :icon="icon"></b-icon>
    </button>
</template>

<script>
    export default {
        name: 'AppIconButton',
        props: {
            handleClick: {
                type: Function,
                default: () => true,
            },
            icon: {
                type: String,
                default: null,
            },
        },
    };
</script>
