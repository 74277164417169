<template>
    <div class="app-informational-banner">
        <b-icon class="app-informational-banner__icon" :icon="icon" />
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'AppInformationalBanner',
        props: {
            icon: {
                type: String,
                default: '',
            },
        },
    };
</script>
