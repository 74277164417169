<template>
    <div class="app-upload-image">
        <div class="app-upload-image__picture-container">
            <input
                type="file"
                ref="file"
                @change="onFileChange"
                :userData="userData"
                class="d-none"
                :accept="accept"
            />
            <img class="xplora-logo" src="@/assets/xplora-logo.png" />
            <img
                class="app-upload-image__picture picture"
                :src="file ? file : cardPicture"
                alt=""
            />
            <app-icon-button
                class="app-upload-image__button"
                icon="camera-fill"
                :handle-click="selectFile"
                type="button"
            ></app-icon-button>
        </div>
    </div>
</template>

<script>
    import AppIconButton from './AppIconButton.vue';

    export default {
        components: { AppIconButton },
        name: 'AppUploadImage',
        props: {
            accept: {
                type: String,
                default: 'image/*',
            },
            title: {
                type: String,
                default: '',
            },
            userData: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        data() {
            return {
                file: '',
                fileName: '',
                image: '',
            };
        },
        computed: {
            cardPicture() {
                if (
                    (this.userData.file === undefined ||
                        this.userData.file.orig === null) &&
                    this.userData.watch === undefined
                ) {
                    const defaultPicture = require.context(
                        '@/assets/user',
                        false,
                        /\.png$/,
                    );
                    return defaultPicture('./default-profile-pic.png');
                }
                return this.userData.file && this.userData.file.orig
                    ? this.userData.file.orig.urlPathS3
                    : this.userData.watch.user.file.orig.urlPathS3;
            },
        },
        methods: {
            selectFile() {
                this.$refs.file.click();
            },
            onFileChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                this.fileName = files[0].name;
                this.createImage(files[0]);
            },
            createImage(file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.file = e.target.result;
                    this.$emit('change', file);
                };
                reader.readAsDataURL(file);
            },
        },
    };
</script>
