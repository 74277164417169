<template>
    <div class="account-settings">
        <app-form
            :title="$t('accountView.accountSettings.title')"
            form-class="account-settings"
            :on-submit="onSubmit"
            :show-overlay="showOverlay"
            :confirmation-title="
                $t('accountView.accountSettings.confirmationTitle')
            "
            :success-message="$t('successMessages.updating')"
            @was-cancelled="showOverlay = false"
        >
            <template v-slot:title-notification>
                <app-informational-banner icon="box-arrow-right">
                    <div class="app-informational-banner__title">
                        {{ $t('lastLogin') }}
                    </div>
                    <div class="app-informational-banner__description">
                        {{ lastLoginDate }}
                    </div>
                </app-informational-banner>
            </template>
            <template v-slot:fields>
                <div class="form-info account-settings__form">
                    <div class="form-info-top">
                        <app-upload-image
                            :title="$t('profile')"
                            :userData="user"
                            @change="changeImage"
                        />

                        <app-input
                            id="name"
                            name="name"
                            class="form-info-left__input"
                            :label="$t('name')"
                            :placeholder="$t('name')"
                            :initial-value="user.name"
                            @update-value="(e) => (name = e)"
                        ></app-input>
                    </div>
                    <div class="form-info-bottom">
                        <app-informational-banner
                            icon="exclamation-circle-fill"
                            class="account-settings__description"
                        >
                            <div class="app-informational-banner__description">
                                {{
                                    $t(
                                        'accountView.accountSettings.newPasswordDescription',
                                    )
                                }}
                            </div>
                        </app-informational-banner>

                        <app-input
                            id="current-password"
                            name="password"
                            class="form-info-left__input"
                            :label="$t('currentPassword')"
                            type="password"
                            :placeholder="$t('currentPassword')"
                            :initial-value="oldPassword"
                            @update-value="(e) => (oldPassword = e)"
                        ></app-input>
                        <app-input
                            id="new-password"
                            name="password"
                            class="form-info-left__input"
                            :label="$t('newPassword')"
                            type="password"
                            :placeholder="$t('newPassword')"
                            @update-value="(e) => (newPassword = e)"
                            :is-valid="isValid"
                            :invalid-feedback="feedback"
                        ></app-input>

                        <app-input
                            id="confirm-password"
                            name="password"
                            class="form-info-left__input"
                            :label="$t('confirmPassword')"
                            type="password"
                            :placeholder="$t('confirmPassword')"
                            @update-value="(e) => (confirmPassword = e)"
                            :is-valid="isValid"
                            :invalid-feedback="feedback"
                        ></app-input>
                    </div>
                </div>
            </template>

            <template v-slot:buttons>
                <b-button
                    class="account-settings__button"
                    @click="showOverlay = true"
                    variant="info"
                    >{{ $t('forms.buttons.update') }}</b-button
                >
                <b-button
                    class="account-settings__button"
                    type="button"
                    variant="secondary"
                    to="/"
                    >{{ $t('forms.buttons.cancel') }}</b-button
                >
            </template>
        </app-form>
    </div>
</template>

<script>
    import { updateMyInfo } from '@/api/graphql/requests';
    import { mapGetters } from 'vuex';
    import uploadImageWithAxios from '@/util/upload-image';
    import AppInput from '../ui/AppInput.vue';
    import AppForm from '../ui/AppForm.vue';
    import AppInformationalBanner from '../ui/AppInformationalBanner.vue';
    import AppUploadImage from '../ui/AppUploadImage.vue';

    export default {
        components: {
            AppInput,
            AppForm,
            AppInformationalBanner,
            AppUploadImage,
        },
        name: 'AccountSetting',
        data() {
            return {
                showOverlay: null,
                name: '',
                oldPassword: null,
                newPassword: null,
                confirmPassword: null,
                isValid: null,
                feedback: '',
                newImage: null,
            };
        },
        computed: {
            ...mapGetters({
                user: 'user/getUser',
            }),
            lastLoginDate() {
                return new Date(this.user.lastLoginDate * 1000)
                    .toString()
                    .split('GMT')
                    .shift();
            },
        },
        methods: {
            async onSubmit() {
                if (this.confirmPassword !== this.newPassword) {
                    this.feedback = this.$t('errors.DoesNotMatch');
                    this.isValid = false;
                    this.showOverlay = false;
                    throw new Error('new password doesnot match');
                }
                const info = {};

                if (this.name !== '') {
                    info.name = this.name;
                } else {
                    info.name = this.user.name;
                }

                if (this.oldPassword !== null) {
                    info.oldPassword = this.oldPassword;
                }

                if (this.newPassword !== null) {
                    info.newPassword = this.newPassword;
                }
                if (this.newImage !== null) {
                    const res = await uploadImageWithAxios(this.newImage);
                    info.profileId = res.data.fileUpload.id;
                }
                info.uid = this.user.id;

                try {
                    await updateMyInfo(info);
                    this.$router.push({
                        name: 'Home',
                    });
                } catch (error) {
                    throw new Error(this.$t('errors.infoUpdate'));
                } finally {
                    this.isValid = null;
                    this.showOverlay = false;
                }
            },
            async changeImage(e) {
                this.newImage = e;
            },
        },
    };
</script>
