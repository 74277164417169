// upload image with axios
const store = require('@/store').default;
const axios = require('axios');

const token = store.getters['auth/getAuthToken'];

export default function uploadImageWithAxios(file) {
    const fileKey = 'upload_account_info_image';
    const formData = new FormData();
    formData.append(fileKey, file);

    return axios({
        method: 'POST',
        url: `${process.env.VUE_APP_URL}/file`,
        data: formData,
        headers: {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'H-date': new Date().toUTCString(),
            'H-File-Key': fileKey,
            'H-BackDoor-Authorization': `Bearer ${token}:27ed7670379511eab4a0f367f8eb1312`,
        },
    })
        .then((response) => response.data)
        .catch((err) => err.status('400 status', err.message));
}

export function uploadCSVFile(file, model, type = '1') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model', model);
    formData.append('type', type);

    return axios({
        method: 'POST',
        url: `${process.env.VUE_APP_URL}/file/imei`,
        data: formData,
        headers: {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'H-date': new Date().toUTCString(),
            'H-BackDoor-Authorization': `Bearer ${token}:27ed7670379511eab4a0f367f8eb1312`,
        },
    }).then((response) => response.data);
}
