<template>
    <div class="account-container">
        <account-settings></account-settings>
    </div>
</template>

<script>
    import AccountSettings from '../components/account/AccountSettings.vue';

    export default {
        name: 'Account',
        components: { AccountSettings },
    };
</script>
